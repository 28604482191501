
import { IonCheckbox, IonItem, IonLabel, onIonViewDidEnter } from "@ionic/vue";
import { reactive, toRefs, watch, onMounted } from "vue";
import OptionMenu from "@/components/optionMenu.vue";

export default {
  name: "SelectQualifications",
  components: {
    IonItem,
    IonLabel,
    IonCheckbox,
    OptionMenu,
  },
  props: {
    modelValue: {
      type: [Number, String],
      default: 0,
      required: false,
    },
    menus: {
      type: Array,
      default: () => [
        // {
        //   label: "你好",
        //   value: 1,
        //   list: [
        //     {
        //       zizhiImgId:
        //         "http://com-hcbx-app-img.oss-cn-beijing.aliyuncs.com/oss-81b1bd42-80b7-430f-80aa-8cc32c54d248(建筑资质).jpg",
        //       zizhiName: "建筑企业资质证啊",
        //     },
        //     {
        //       zizhiImgId:
        //         "http://com-hcbx-app-img.oss-cn-beijing.aliyuncs.com/oss-81b1bd42-80b7-430f-80aa-8cc32c54d248(建筑资质).jpg",
        //       zizhiName: "建筑企业资质证你",
        //     },
        //   ],
        // },
      ],
      required: false,
    },
  },
  emits: {
    "update:modelValue": null,
    close: null,
  },
  setup(props: any, { emit }: any) {
    const data = reactive({
      checkTab: null,
      menuList: props.menus,
    });

    watch(
      () => props.menus,
      (current) => {
        if (current.length > 0) {
          const index = props.menus.length > 0 && data.checkTab && props.menus.findIndex(({ value }) => value === data.checkTab);
          data.checkTab = props.menus[index || 0].value;
        }
      },
      {
        deep: true,
      }
    );

    const close = () => {
      emit("close", false);
    };

    watch(
      () => data.menuList,
      (current) => {
        const checks = current
          .reduce((back, curr) => [...back, ...curr.list], [])
          .filter(({ isChecked }) => isChecked);
        emit("update:modelValue", checks);
      },
      {
        deep: true,
      }
    );

    return { ...toRefs(data), close };
  },
};
