import { toastController } from "@ionic/vue";

let tips: any;

export const openTips = async (message: string, duration = 2000) => {
  tips = await toastController
    .create({
      message,
      duration,
      position: "top",
      cssClass: "tips-container"
    })
  return tips.present();
}

export const closeTips = () => {
  tips.dismiss();
}