import { modalController } from "@ionic/vue";
import Success from "@/components/success.vue"

let modal: any;

export const openModal = async (message: string) => {
  modal = await modalController.create({
    cssClass: "modal-container",
    component: Success as any,
    componentProps: {
      message
    },
  })
  return modal.present();
}

export const closeModal = () => {
  modal.dismiss();
}