
import { closeModal } from "@/utils/alert";

export default {
  name: "Success",
  props: {
    message: {
      type: String,
      default: "",
    },
  },
  setup() {
    return {
      closeModal
    };
  },
};
