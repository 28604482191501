
import { ref, nextTick } from "vue";

export default {
  name: "optionMenu",
  props: {
    type: {
      type: String,
      default: "block", // slider、block
      required: true,
    },
    options: {
      type: Array,
      default: () => [
        {
          label: "标题",
          value: 0,
        },
      ],
      required: true,
    },
    modelValue: {
      type: [Number, String],
      default: 0,
      required: false,
    },
  },
  emits: {
    "update:modelValue": null,
  },
  setup(props: any, { emit }: any) {
    const scrollContainer = ref(null);

    const scroll = () => {
      nextTick(() => {
        scrollContainer.value.querySelector(".checked").scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      });
    };

    const toggle = (value: any) => {
      emit("update:modelValue", value);
      if (props.type === "slider") scroll();
    };

    return { toggle, scrollContainer };
  },
};
