import { modalController } from "@ionic/vue";
import BigPicture from "@/components/bigPicture.vue"

let images: any;

export const openImages = async (imgs: Array<string>, index: number) => {
  images = await modalController.create({
    cssClass: "modal2-container",
    component: BigPicture as any,
    componentProps: {
      imgs,
      index
    },
  })
  return images.present();
}

export const closeImages = () => {
  images.dismiss();
}