import axios from 'axios'
import { local } from '@/utils/storage.service'
import { openTips } from "@/utils/alert";
const http = axios.create({
  timeout: 30000,
  baseURL: '/',
})

http.interceptors.request.use(
  (config) => {
    const token = local.get('TOKEN')
    config.headers = {
      ...config.headers,
      accept: 'application/json;charset=UTF-8',
      authorization: token ? `Bearer ${token}` : ''
    }

    let loginuserid = "";
    const loginuser = local.get("loginuser");
    if (loginuser != undefined) {
      loginuserid = loginuser.userid
    }

    config.data = {
      data: config.data,
      token,
      userid: loginuserid,
      memberid: loginuserid,
      teamno: '1001',
      platform: 'any'
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

const whiteList = [200, 201]
http.interceptors.response.use(
  (response: any) => {
    if (response.config.responseType === 'arraybuffer') {
      const base64 = `data:image/png;base64,${window.btoa(
        new Uint8Array(response.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      )}`
      return Promise.resolve(base64)
    }
    if (whiteList.includes(response.status) && response.data.status.code === '0') {
      return Promise.resolve(response.data.result)
    }else{
      openTips(response.data.status.desc);
    }
    return Promise.reject(response)
  },
  (error: any) => {
    return Promise.reject(error)
  }
)

export default http
