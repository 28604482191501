import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4fb5c424"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "options-container"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 1,
  class: "options-container slider",
  ref: "scrollContainer"
}
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($props.type === 'block')
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (item) => {
            return (_openBlock(), _createElementBlock("li", {
              key: item.value,
              onClick: ($event: any) => ($setup.toggle(item.value)),
              class: _normalizeClass(['option', { checked: $props.modelValue === item.value }])
            }, _toDisplayString(item.label), 11, _hoisted_2))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    ($props.type === 'slider')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.value,
              onClick: ($event: any) => ($setup.toggle(item.value)),
              class: _normalizeClass(['option', { checked: $props.modelValue === item.value }])
            }, _toDisplayString(item.label), 11, _hoisted_4))
          }), 128))
        ], 512))
      : _createCommentVNode("", true)
  ], 64))
}