//const BASE_URL = "http://zdyapi.hcbxcrm.com/";
const BASE_URL = "https://zdyapi.zd-jk.cn/";
//const BASE_URL = "https://localhost:44386/";
const PREFIX = "zdy/";

export const homeApi = {
  slides: `${BASE_URL}${PREFIX}getadvertlist`, //首页轮播图
  qualifications: `${BASE_URL}${PREFIX}getzizhiimglist`, //资质列表
  gethomedatainfo: `${BASE_URL}${PREFIX}gethomedatainfo`, //资质列表
  feedbackcommit: `${BASE_URL}${PREFIX}feedbackcommit`, //资质列表
  getzizhiforhomelist: `${BASE_URL}${PREFIX}getzizhiforhomelist`, //获取企业资质图片的信息 给首页下拉框
}

export const informationApi = {
  list: `${BASE_URL}${PREFIX}getnewslist`, //资讯列表
  detail: `${BASE_URL}${PREFIX}getnewsdetail`, //资讯详情
  comment: `${BASE_URL}${PREFIX}newscommentcommit`, // 提交资讯留言
}

export const discoverApi = {
  recruitList: `${BASE_URL}${PREFIX}getzhaopinlist`, // 招聘列表
  recruitDetail: `${BASE_URL}${PREFIX}getzhaopindetail`, // 招聘详情
  submitRecruit: `${BASE_URL}${PREFIX}zhaopinusercommit`, // 提交招聘信息
  tenderList: `${BASE_URL}${PREFIX}gettenderlist`, // 投标列表
  tenderDetail: `${BASE_URL}${PREFIX}gettenderdetail`, // 投标详情
  submitTender: `${BASE_URL}${PREFIX}tendersuppliercommit`, // 提交投标信息
}

export const memberApi = {
  checklogin: `${BASE_URL}${PREFIX}checkLogin`, // 登录注册
  getsimplelist: `${BASE_URL}dataitem/simplelist`, // 查询字典配置
  getteamsetting: `${BASE_URL}dataitem/getteamsetting`, // 查询设置配置
  sendverifycode: `${BASE_URL}user/sendverifycode`, // 查询字典配置
  gettongjidata: `${BASE_URL}${PREFIX}gettongjidata`, // 查询统计配置
  feedbackcommit: `${BASE_URL}${PREFIX}feedbackcommit`, // 反馈留言
  getuserfeedbacklist: `${BASE_URL}${PREFIX}getuserfeedbacklist`, // 反馈留言
  getuserdetail: `${BASE_URL}${PREFIX}getuserdetail`, // 获取用户信息
  supplierdatacommit: `${BASE_URL}${PREFIX}supplierdatacommit`, // 供应商信息提交
  workerdatacommit: `${BASE_URL}${PREFIX}workerdatacommit`, // 技术工人信息提交
  getuserworkerorsupplierdetail: `${BASE_URL}${PREFIX}getuserworkerorsupplierdetail`, // 技术工人或者供应商信息获取
  getusertenderlist: `${BASE_URL}${PREFIX}getusertenderlist`, // 获取用户招投标列表
  getusertenderdetail: `${BASE_URL}${PREFIX}getusertenderdetail`, // 获取用户招投标详情
  getusertendernoticedetail: `${BASE_URL}${PREFIX}getusertendernoticedetail`, // 获取用户招投标中标详情
}


export const loginApi = {
  login: `${BASE_URL}${PREFIX}login`, //登录接口
}

export const commonApi = {
  uploadImage: `${BASE_URL}uploadimgby64`, //上传图片接口
}

