import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a493d4a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "full picture-container align-center" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.imgs, (src, i) => {
      return _withDirectives((_openBlock(), _createElementBlock("img", {
        key: i,
        src: src,
        class: "slide-image"
      }, null, 8, _hoisted_2)), [
        [_vShow, i === $setup.current]
      ])
    }), 128)),
    _createVNode(_component_ion_icon, {
      icon: $setup.chevronBack,
      onClick: $setup.backImage,
      class: "icon-btn back-btn"
    }, null, 8, ["icon", "onClick"]),
    _createVNode(_component_ion_icon, {
      icon: $setup.chevronForward,
      onClick: $setup.nextImage,
      class: "icon-btn next-btn"
    }, null, 8, ["icon", "onClick"]),
    _createVNode(_component_ion_icon, {
      icon: $setup.close,
      onClick: $setup.closeImages,
      class: "icon-btn close-btn"
    }, null, 8, ["icon", "onClick"])
  ]))
}