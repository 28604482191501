
import { closeImages } from "@/utils/alert";
import { add, close, chevronBack, chevronForward } from "ionicons/icons";
import { IonIcon } from "@ionic/vue";
import { ref, onMounted } from "vue";

export default {
  name: "BigPicture",
  props: {
    imgs: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  setup(props: any) {
    const current = ref(props.index);
    const backImage = () => {
      if(current.value > 0) {
        current.value -= 1;
      }
    };
    const nextImage = () => {
      if(current.value < props.imgs.length - 1) {
        current.value += 1;
      }
    };
    
    return {
      current,
      backImage,
      nextImage,
      closeImages,
      IonIcon,
      add,
      close,
      chevronBack,
      chevronForward
    };
  },
};
